<template>
    <v-container grow d-flex flex-column flex-nowrap style="height: calc(100vh - 50px)">
        <v-row no-gutters>
            <v-col cols="12" class="d-flex flex-column align-center justify-center">
                <h3>{{ 'The ' + (resource ? resource : 'page') + ' could not be found.' }}</h3>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "NotFound",
        props: ['resource']
    }
</script>

<style scoped>

</style>